@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");

.login-container {
  background: #fafafa;
}

.rowDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
}

.adminLogin {
  margin-top: 40%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 100px 0px #f0f0f0;
  padding: 10px;
  form {
    display: flex;
    flex-direction: column;
    text-align: center;
    h2 {
      color: #6a2ff9;
      font-family: Nunito Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    label {
      display: flex !important;
      color: rgba(113, 111, 120, 0.75);
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      margin-bottom: 10px;
    }
    input {
      outline: none;
      border: none;
      width: 320px;
      height: 45px;
      border: 1px solid #cecdcd;
      background: #ffffff;
      margin-bottom: 20px;
      padding-left: 10px;
    }
    button {
      border: none;
      outline: none;
      border-radius: 6px;
      background: #6a2ff9;
      box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.19);
      width: 320px;
      height: 45px;
      padding: 14px 9px;
      color: #fff;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    span {
      display: flex;
      justify-content: end;
      color: #6a2ff9;
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }
}

.rowContainer {
  margin: 0px !important;
}

.navbar {
  height: 15vh;
}

.nav-link {
  display: flex !important;
  align-items: center;
  color: #707070 !important;
  text-align: center;
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px !important;
  svg {
    color: #707070;
  }
}
.rounded-circle {
  height: 8vh;
}
.categoryAdminContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 10%;
  margin-bottom: 100px;
}

.CategoryCard {
  border-radius: 10px;
  box-shadow: 0px 0px 100px 0px #f0f0f0;
  width: 300px;
  height: 250px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  cursor: pointer;

  img {
    width: 60px;
  }
  h1 {
    // color: #FFF;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Nunito Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 15px;
  }
}
.searchBoxContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  .riteSideContainer {
    width: 60%;
    border-radius: 4px;
    border: 1px solid #fff;
    background: #fff;
    position: relative;
    margin-left: 20px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    input {
      width: 100%;
      border: none;
      outline: none;
      padding-left: 50px;
    }
    svg {
      position: absolute;
      left: 16px;
      bottom: 5px;
    }
  }
  .leftSideContainer {
    display: flex;
    justify-content: space-between;
    margin-right: 49px;
    display: flex;
    align-items: center;
    .drop {
      margin-right: 5px;
      background-color: #ffffff;

      button {
        background-color: #ffffff;
        border: none;
        color: #707070;
        width: 187px;
        height: 40px;
        box-shadow: none;
      }
    }
    button {
      border-radius: 5px;
      border: 1px solid #986ff9;
      background: #986ff9;
      color: #ffffff;
      width: auto;
      height: 41px;
    }
  }
}
.dashBoardBodyContainer {
  display: flex;
  // justify-content: space-between;
  margin-top: 90px;
}
.tableContainer {
  width: 80%;
  background-color: #fafafa;
  margin-left: 20px;
}

#actions {
  color: #6a2ff9;
  display: table-cell;
  align-items: center;
  justify-content: space-between;
}
.addUserForm {
  width: 80%;
  margin-left: 20px;
  .btnContainer {
    button {
      border-radius: 5px;
      background: rgba(106, 47, 249, 0.25);
      color: rgba(106, 47, 249, 0.58);
      font-family: Nunito Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 23px;
      border: none;
      outline: none;
      padding: 15px;
    }
  }
  .addCardDiv {
    h2 {
      color: #000;
      font-family: Nunito Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 30px;
    }
    input {
      border-radius: 3px;
      border: 1px solid rgba(112, 112, 112, 0.3);
      background: #fff;
      height: 35px;
      width: 620px;
    }
    label {
      color: #000;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .cardDate {
    display: flex;
    justify-content: flex-start;

    input {
      width: 308px;
      margin-right: 5px;
    }
  }
  .pasword {
    display: flex;
    input {
      width: 308px;
      margin-right: 5px;
    }
    #recoverPassword {
      border-radius: 5px;
      border: 1px solid #986ff9;
    }
    .paswContainer {
      display: flex;
      align-items: flex-end;
    }
  }
}
.tabsContainer {
  width: 80%;
  margin-left: 20px;
  .topTabsMenuContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .togleBtnContainer {
      div {
        border-radius: 100px;
        background: #fff;
        box-shadow: 0px 0px 10px 0px rgba(222, 208, 255, 0.78);
        width: 200px;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 5px;
        margin-right: 100px;
        button {
          border: none;
          outline: none;
          padding: 5px;
          width: 100px;
          border-radius: 20px;
          background-color: #6a2ff9;
          color: #fff;
          margin-right: 10px;
        }
      }
    }
  }
}

.descriptionContainer {
  margin-top: 20px;
  .desciptionTopContainer {
    display: flex;
    justify-content: space-between;
    .leftContainer {
      display: flex;
      flex-direction: row;
      img {
        width: 100px;
        height: 100px;
        border-radius: 100px;
        margin-right: 20px;
        object-fit: cover;
      }
      div {
        div {
          display: flex;
          flex-direction: row;
          margin: 0px;
          margin-top: 10px;
          align-items: center;
          h1 {
            color: #646464;
            font-family: Nunito Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0px;
            padding: 0px;
          }
          span {
            color: #707070;
            font-family: Nunito Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            a {
              color: #6a2ff9;
              text-decoration-line: underline;
            }
          }
          svg {
            color: #6a2ff9;
            margin-right: 5px;
          }
        }
      }
    }
    .riteContainer {
      div {
        display: flex;
        flex-direction: row;
        margin-right: 50px;

        button {
          border-radius: 5px;
          border: 1px solid #986ff9;
          background: #fff;
          color: #986ff9;
          font-family: Nunito Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: 150px;
          height: 40px;
          margin-right: 15px;
        }
        #deactivate {
          border-radius: 5px;
          border: 1px solid #986ff9;
          background: #986ff9;
          color: #fff;
          font-family: Nunito Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  .desciptionBottomContainer {
    span {
      color: #707070;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }
    p {
      color: #707070;
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.analyticContainer {
  .analyticTop {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-right: 50px;

    span {
      color: #707070;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }
  }
  .analyticBottom {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;

    .Analyticcards {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 240px;
      height: 117px;
      flex-shrink: 0;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0px 0px 20px 0px rgba(197, 197, 197, 0.25);

      padding: 2px 15px;
      margin: 10px;
      .titleContainer {
        span {
          color: rgba(108, 108, 108, 0.53);
          font-family: Nunito Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .textIconContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: #6c6c6c;
          font-family: Nunito Sans;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
.serviceofferingBottom {
  display: flex;
  .leftSideContainerservice {
    width: 60%;
    border-right: 2px solid #e9e9e9;
    div {
      padding-right: 10px;
      p {
        color: #707070;
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .riteSideContainerservice {
    margin-left: 50px;
    .leftUploadBtnContainer {
      .uploadBtn {
        border-radius: 5px;
        background: rgba(106, 47, 249, 0.05);
        padding: 10px 15px;
        color: #6a2ff9;
        font-family: Nunito Sans;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        span {
          svg {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.documentUploadContainer {
  margin-top: 20px;
  h1 {
    color: #000;
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  div {
    border-radius: 5px;
    background: rgba(106, 47, 249, 0.05);
    padding: 10px 15px;
    width: 320px;

    span {
      color: #6a2ff9;
      font-family: Nunito Sans;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 23px;
      svg {
        margin-left: 30px;
      }
    }
  }
}
.rieweContainer {
  margin-top: 20px;
  h1 {
    color: #000;
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ratingCardContainer {
    margin-top: 20px;

    .ratingCard {
      margin-bottom: 20px;
      span {
        color: #000;
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .countContainer {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        span {
          color: #faaf00;
        }
        .starCount {
          background: #faaf00;
          color: #fff;
          width: 30px;
          align-items: center;
          justify-content: center;
          display: flex;
        }
        .calendarContainer {
          margin-left: 20px;
          span {
            color: #707070;
            font-family: Nunito Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
          svg {
            color: #707070;
            margin-right: 5px;
          }
        }
      }
      #disamble {
        color: #707070;
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 15px;
      }
    }
  }
}

#dropdown-basic {
  border-radius: 4px;
  background: #fff !important;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  color: #868686;
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 62.5% */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 187px;
  height: 35;
}

// posted project
.projectsearchBoxContainer {
  display: flex;
  justify-content: space-between;

  padding-left: 27px;
  width: 94.5%;
  div {
    width: 167px;
    height: 40px;
    border-radius: 4px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: #707070;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    svg {
      width: 24px;
      height: 24px;
      color: #707070;
    }
  }
}

#projectStateBtn {
  border: none;
  outline: none;

  border-radius: 20px;
  background: rgba(255, 0, 0, 0.15);
  color: #f00;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px;
}

#projectStateBlueBtn {
  border: none;
  outline: none;

  border-radius: 20px;
  background: rgba(235, 229, 250, 0.95);
  color: #6A2FF9;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px;
}

.activemenu
{
    background-color: #986ff9;
    display: block;
}    
.activemenu span
{
    color:#FFF !important;
}