h1, h2, h3, h4, h5, h6{
    // font-family: 'Noto Sans', sans-serif !important;
    font-family: 'Nunito Sans', sans-serif;
}
p{
    // font-family: 'Loto', sans-serif !important;
    font-family: 'Nunito Sans', sans-serif;
}

@import "~bootstrap/scss/bootstrap";
@import "../src/Components/Header/Header";
@import "../src/Screens/Home/Style/Home";
@import "../src/Screens/Screens";

iframe#webpack-dev-server-client-overlay {
    display: none;
}

// body {
//     margin: 0;
//     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//       'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//       sans-serif;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }

//fonts 
//1. Noto sans
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;400;600;800;900&display=swap');
//2. Loto
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
//3. nunito sans
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap');
//   code {
//     font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//       monospace;
//   }


