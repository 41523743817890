@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400&display=swap');

// .search button .custom-btn-add{
//     position: absolute;
//     top: 11px !important;
//     right: 10px;
//     height: auto;
//     width: auto;
//     /* height: 50px; */
//     /* width: 110px; */
//     /* margin: auto 0; */
//     background: $primary-color-purple;
//     border-radius: 0.24em;
// }
$progress-bar-bg:  #6A2FF9;

// search bar styling 
.add{
    position: relative;
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
      
    }

    .add input{

     height: 60px;
     text-indent: 25px;
     border: 0px solid #d6d4d4;
    
    
    }
    .add input::placeholder{
        font-size: 0.7em;
    }

    .add input:focus{

     box-shadow: none;
     border: 2px solid blue;


    }

    .add .fa-search{

     position: absolute;
     top: 20px;
     left: 16px;


    }
    .add .fa-location{

        position: absolute;
        top: 20px;
        left: 16px;
   
       }

    .add button{

     position: absolute;
     top: 11px;
     right: 10px;
    
     background: $primary-color-purple;
    border-radius: 0.24em;
    }

.select-category-div{
    button{
        width:100% !important;
        background-color: transparent;
        border-radius: 0.3em;
     
        // border: 1px solid red;
        min-height: 50px;
        padding: 0.5em 1em;
        font-size: 0.9em;
        color: #707070;;
    }
    
    // button:active{
    //     width:100% !important;
    //     background-color: $primary-color-purple;
    //     border-radius: 0.3em;
    //     color:white;
    //     border: 1px solid $primary-color-purple;
    //     min-height: 60px;
    // }
}
.skills-buttons-div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    p{
        font-family: Nunito Sans;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #3D3939;
    }
    div{
                        display: "flex";
                        justify-content: center;
                        align-items: center;
                        width: 248px;
                        height: 48px;
                        left: 88px;
                        top: 782px;
                        background: #FFFFFF;
                        box-shadow: "0px 4px 5px rgba(0, 0, 0, 0.08)";
                        border-radius: 5px;
                        input{
                            border: none;
                          outline: none;
                        }
                        svg{
                            color: #6A2FF9;
                            font-size: 2.3rem; 
                        }
    }
    button{
        margin: 0.3em;
        padding:0.2em 0.5em;
        background-color: #F4F4F4;
        color:rgb(132, 131, 131);
        border:none;
        border-radius: 0.3em;
    }
}

.rangeContainer{
    p{
        font-family: "Nunito Sans";
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color:#3D3939;
    }
   
}

.tagsContainer{
    p{
        font-family: "Nunito Sans";
                      font-weight: 600;
                      font-size:20px;
                      line-height: 27px;
                      color: #3D3939;
    }
   
}

.search-skills{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    button{
        margin: 1em 0.5em;
        padding:0.2em 0.5em;
        background-color: lightgray;
        color:rgb(132, 131, 131);
        border:none;
        border-radius: 0.3em;
    }
}
.search-panel-service{
    position: relative;
    margin: 1em auto;
    span{
        position: absolute;
        top: 50px;
        left: 10px;
        z-index: 100;
    }
    .sort-by-span{
        position: absolute;
        bottom:0;
        left: 78%;
        z-index:100;
        font-weight: 600;
    }
    div{
        position: relative;
    }
    input{
        min-height: 46px;
        padding-left: 2em;
    }
    input::placeholder{
        padding-left:1.3em;
    }
    button{
        background-color: transparent;
        color: black;
        border: 1px solid lightgray;

    }
    button:hover, button:active{
        background-color: $primary-color-purple;
        color: white;
        border:none;
    }
}
.service-card-horizontal{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1em auto;
}
.avatar-title-rating-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.information-section-card2{
    width:40%;
}


//work module starts here
.switch-btn-div{
    form{
        margin: 1em auto;
        div{
            margin: 0.3em auto;
        }
    }
}
.work-project-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2em auto;
    padding: 0 !important;

}
.star-icon{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 0 auto auto auto;
    span{
        background-color: #D3D3D3;
        border-radius: 15em;
        height: 6vh;
        width: 6vh;
        padding: 1em;
        margin: 1em;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

       svg{
        color:#868686;
        height: 3vh;
        width: 3vh;
       }
    }
}
.details-of-vendor{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0.5em auto 0.5em 0.5em;
    width: 100%;
    div{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-height: 100%;
        width: 20%;
        p{
            margin: 0 0.2em;
            font-family: 'Nunito Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
color: #707070

        }
        span{
            min-height: 3vh;
            min-width: 3vh;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            svg{
                color: #888888;
                min-height: 1.5vh;
                min-width: 1.5vh;
            }
        }
    }
}
.project-card-avatar{
    // margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
    margin-left: 20px;
    img{
        height: 4vh;
    }
    h1{
        font-size: 0.8em;
        font-weight: 600;
    }
   p{
     color: $light-gray-p;
     margin-bottom: 0.5em;
     font-size: 0.8em;
     margin-top: 2em;
   }
   .link-div{
    margin: 0.2em 0 1em 0;
   }
}
.content-for-project{
    // margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    margin-left: 20px;
    h1{
        font-size: 0.8em;
        font-weight: 600;
        font-family: 'Nunito Sans';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 33px;

color: #222222;
    }
   p{
     color: $light-gray-p;
     margin-bottom: 0.5em;
     font-size: 0.8em;
     margin-top: 2em;
   }
   .link-div{
    margin: 0.2em 0 1em 0;
   }
}
.custom-gray-row{
    // border-top: 1px solid #bdc0c354;
    // background-color: #F4F4F4;
    background-color: #F2F2F2;;
}
//over riding _utility

//service details page
.title-desc-card2{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 3.2em auto;
    p:nth-child(1){
        color:black;
        margin: "0px";
                        font-family: "Nunito Sans";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 41px;
                        color: #565050;
       
    }
    p:nth-child(2){
        font-family: "Nunito Sans";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 41px;
                        color: #565050;
        
    }
    .rating-div2{
        display: flex;
        flex-direction: row;
        // justify-content: center;
        align-items: center;
        
       
       
        span{
            margin: auto 0.2em;
            background-color: $yellow-color-geegr;
            color:white;
            padding: 0.2em 0.25em;
            border-radius: 0.2em;
                width: 33px;
    /* height: 24px; */
    text-align: center;

    
        }
        div{

            span{
                color: $yellow-color-geegr;
                background-color: transparent;
            }
        }
        .country-flag-div{
            display: flex;
            flex-direction: row;
            margin: auto 1em;
            p{
                margin: 0.5em auto;
                text-align: center;
                margin: auto 2em auto 0.5em;
            }
            img{
                margin: 0.5em 0.3em;
                width:2em;
            }
        }
        .verification-btn-div{
            span{
                display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 2px;
}
            
            margin: 0px;
            button{

            display: flex;
            flex-direction: row;

            border: none;
            background-color: $success-color-green;
            color: white;
            padding-left: 0.7em;
            padding-right: 0.7em;
            padding-top: 0.4em;
            padding-bottom: 0.4em;
            border-radius: 0.3em;
                span{
                    color:white;
                    margin: auto;

                }
                p{
                    margin:auto;
                    color:white;
                    font-weight: 400;
                    text-align: center;
                }
            }
span{
    color: #fff;
}
        }
    }
}
// .top-section-container {
//     padding: 105px 0;
//     position: relative;
//     background-image: url("https://images.unsplash.com/photo-1598084991519-c90900bc9df0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80");
// }
// .top-section-container:before {
//     content: "";
//     position: absolute;
//     height: 100%;
//     width: 100%;
//     display: block;
//     top: 0;
//     left: 0;
//     z-index: 15;
//     background: linear-gradient(to right,rgba(250,250,250,1) 20%, rgba(250,250,250,.95) 50% ,rgba(250,250,250,0.1) 80%,rgba(250,250,250,0) 100%);
// }

.about-div{
    padding: "0px";
    margin-bottom: "44px";
    font-family: "Nunito Sans";
    font-style: "normal";
    font-weight: "400";
    font-size: "20px";
    line-height: "23px";
    color: "#000000";
    span{
        font-family: 'Nunito Sans', 'sans-serif';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
    }
    p{
        font-family: 'Nunito Sans';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 23px;
color: #898484;
padding:0px;
    }
    padding: 49px 2em 0 4.1em;
    margin: 2em auto;
   p:nth-child(1){
    font-size: 1.9em;
    font-weight: 500;
   }
   p:nth-child(2){
    padding: 26px 2em;
    color: $light-gray-p;

   }
}
.gray-panel{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-height: 6vh;
    padding: 0 0em 0px 4em;
    margin: 2em 0 0 0px;
    margin-bottom: 60px;
    div{
        display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    // border: 1px solid gray;
   
    background-color: #F2F2F2;
    width:100%;
    padding: 0.5em 0.6px 10px 11px;
    p{
        padding: 0;
        margin:auto 1em;

    }
    span{
        color:$primary-color-purple;
        min-height: 4vh;
        min-width: 4vh;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            height: 3vh;
            width: 3vh;
        }
    }
}
}

.work-history-info{
    // padding: 0 2em 0 10em;
    // margin: 2em auto;
    margin-bottom: 30px;

    div{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0.5em 2em;
        width:100%;
        .rating-div{
            display: row !important;
            padding: 0 !important;
            align-items: center;
        }
    .work-history-title{
        p{
            margin: 0;
            font-family: 'Nunito Sans';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 23px;

color: #000000;

        }
        
    }
    div{
        margin: 0px;
        p{
            font-family: 'Nunito Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */
color: #707070;
margin: 0px;
padding: 0px;

        }
    }
        .rating-div2{
            display: flex;
            flex-direction: row;
width: 300px;

            // justify-content: center;
            align-items: center;
            // span{
            //     width: 32px !important;
            //     height: 24px;
            //     display: flex;
            //     align-items: center;
            //     font-size: 14px;
            //     font-weight: 400;
            //     padding: 5px;
            // }
          .starContainer{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 133px;
            align-items: center;
            color: $yellow-color-geegr;

        
          }
            span{
              
                background-color: $yellow-color-geegr;
                color:white;
                    padding: 0.2em 2.01px;
                border-radius: 0.2em;
        
            }
            div{
                margin: 0px;
                padding: 0px;
                span{
                    color: $yellow-color-geegr;
                    background-color: transparent;
                }
            }
            .country-flag-div{
                display: flex;
                flex-direction: row;
                margin: auto 1em;
                p{
                    margin: 0.5em auto;
                    text-align: center;
                    margin: auto 2em auto 0.5em;
                }
                img{
                    margin: 0.5em 0.3em;
                    width:2em;
                }
            }
           .date-time-div{
            display: flex;
            flex-direction: row;
            min-height: 4vh;
            align-items: center;
            margin-left: 20px;

            p{
                text-align: center;
                margin:0;
                color: $light-gray-p;
                width: 100px;
                font-family: 'Nunito Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 23px;
/* or 144% */


color: #707070;
            }
              span{
                display: flex;
                align-items: center;
                svg{
                    color:$light-gray-p;
                }
               
              }
              
           }
        }
    }
}
.gray-background{
    background-color: #FAFAFA;
}
.pagination-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1em auto;

    .page-link{
        color: $primary-color-purple;
    }
    .page-link.active, .active > .page-link{
        background-color: $primary-color-purple;
        border-color: $primary-color-purple;

    }
}
.Attachment-div{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: calc(100% + 20px);
    margin-bottom: -30px;
span{
    font-family: 'Nunito Sans';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 23px;
color: #000000;
margin-top: 10px;
margin-bottom: 30px;
}
    a{
        text-decoration: none;
        outline: none !important;
        cursor: pointer;
        span{
            color: #575454;
            font-family: 'Nunito Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 10px;
/* or 62% */

letter-spacing: -0.01em;
        }
        i{
            color: #575454;
            font-family: 'Nunito Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 10px;
/* or 62% */

letter-spacing: -0.01em;
        }
    }
    .attachment-box{
        display: flex;
    margin: 0 20px 20px 0;
    background-color: #f4f4f4;
    border-radius: 4px 0px 4px 4px;
    font-weight: 600;
    padding: 15px 20px;
    padding-bottom: 45px;
    padding-right: 25px;
    line-height: 24px;
    flex-direction: column;
    color: #666;
    position: relative;
    transition: 0.3s;
    flex: 0 1 calc(50% - 21px);
    cursor: default;
    position: relative;
    }
    .attachment-box:hover{
        background-color: $primary-color-purple;
        color:white;
    }
    .ripple-effect-dark, .ripple-effect{
        overflow: hidden;
    position: relative;
    z-index: 1;
    }
    .attachment-box:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        border-width: 0 20px 20px 0;
        border-style: solid;
        border-color: rgba(0,0,0,0.15) #fff;
        transition: 0.3s;
        border-radius: 0 0 0 4px;
    }
    
}

.rate-n-basic-details{
    display: flex;
    flex-direction: row;
    align-items: center;
   margin-top: 2.5em;
    .flex-column{

        
        min-height: 100%;
        span{
            display: block;
            margin: auto 0.5em;
            text-align:left;
            padding: 0.2em 1em;
            font-size: 1.2em;
            font-weight: 500;

            p{
                font-size: 1em;
                color:$light-gray-p;

            }
        }
    }
    .flexCharges{
        border-right: 1px solid $light-gray-p;
    }
}
.make-offer-btn{
    margin: 1em auto;
    
    button{

       display: block;
        width:100%;
        border: none;
        background-color:  $primary-color-purple;
        color:white ;
        padding-left: 0.7em;
        padding-right: 0.7em;
        padding-top: 1em;
        padding-bottom: 1em;
        border-radius: 0.3em;
        text-align: center;
        min-height: 4vh;
        display: flex;
        // border: 1px solid $primary-color-purple;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    

            
        }
        button:hover{

            display: flex;
             width:100%;
             border: 1px solid $primary-color-purple;
             background-color: transparent;
             color: $primary-color-purple;

             padding-left: 0.7em;
             padding-right: 0.7em;
             padding-top: 1em;
             padding-bottom: 1em;
             border-radius: 0.3em;
             text-align: center;
             min-height: 4vh;
             align-items: center;
    justify-content: space-evenly;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
     
                 
             }
}
.progress-bar-info-div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin: 1em auto;
    div{
        p{
            font-size: 1.2em;
            font-weight: 500;
            margin: 0.5em 0 0.5em 0;
        }
        span{
            p{
                font-size: 1em;
                color: $light-gray-p;
                margin-bottom: 0;
            }
        }
        .progress{
            height: 0.5em;
            --bs-progress-bar-bg: #6A2FF9;
        }
    }
    
}
.social-icons-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1em auto;
    p{
        font-size: 1.4em;
        font-weight: 500;

    }
    div{
        i{
            margin: auto 1em;
        }
    }
}
.geegr-verification-div{
    button{

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      width: 219px;
height: 53px;
        border: none;
        background-color: #6A2FF9;
        color: white;
        padding-left: 0.7em;
        padding-right: 0.7em;
        padding-top: 0.4em;
        padding-bottom: 0.4em;
        border-radius: 0.3em;
        
font-family: 'Nunito Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;

color: #FFFFFF;
            span{
                color:white;
                margin: 0px;
                margin-right: 10px;                svg{
                    margin-right:1em;
                }
            }
            p{
                margin:0px;
                color:white;
                font-weight: 400;
                text-align: center;
            }
        }
}
.skills-div{
    h3{
        font-family: 'Nunito Sans';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 23px;
color: #000000;
    }
    margin: 2em auto;
    input{
        display: block;
        background: #F4F4F4;
        margin: 0.5em auto;
        padding: 1em 2em;
        color:black;
        font-weight: 100;
        border-radius: 0.2em;
        outline: none;
        border: none;
        width: 100%;
    }

}
#top-section-row{
    background-image: url(../assets/images/background1.png);
    background-size: cover;
    height:100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 1em 0.5em;
}
.work-detials-div{
    padding-right: 4em;
}
.employment-history-div{
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:flex-start;
    padding: 0 2em 0 10em;
    margin: 2em auto;
    
    .avatar-div{
        width:25%;
        margin: 0 1em;
    }
    .employment-history-details{
        .icon-n-details{
            display: flex;
            flex-direction: row;
            justify-content:flex-start;
            align-items: center;
            div{
                display: flex;
                flex-direction: row;
                margin: auto 1em;
                align-items: center;
                p{
                    margin: auto  0 auto 0.5em;
                    text-align: center;
                    color: $light-gray-p;
                }
                span{
                    color: $light-gray-p;
                }
            }

        }
        .employment-history-description{
            margin: 1em auto;
            p{
                font-weight: 200;
            }
        }
    }

}

//modal service detail page
.btn-modal-div{
    display: flex;
    flex-direction: row;
    
    button:nth-child(2){
        margin: auto 0.5em;
       
        border: none;
        background-color: $primary-color-purple;
        color: white;
        padding-left: 0.7em;
        padding-right: 0.7em;
        padding-top: 0.4em;
        padding-bottom: 0.4em;
        border-radius: 0.3em;
        min-height: 46px;   
    }
    button:nth-child(1){
        margin: auto 0.5em;
       
        border: 1px solid $primary-color-purple;
        background-color: white;
        color: $primary-color-purple;
        padding-left: 0.7em;
        padding-right: 0.7em;
        padding-top: 0.4em;
        padding-bottom: 0.4em;
        border-radius: 0.3em;
        min-height: 46px;    
    }
}
.title-desc-card3{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 1em auto;
    p{
        margin: 0;
    }
    p:nth-child(1){
        color:black;
        font-size: 2em;
        font-weight: 500;
       
    }
    p:nth-child(2){
        color:black;
        font-weight: 100;
        font-size: 1.0em;
        
    }
  
    .rating-div3{
        display: flex;
        flex-direction: row;
        // justify-content: center;
        align-items: center;
       .working-place{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: auto 1em auto 0;
     
            p{
                margin:0;
            }
        
       }
        span{
            margin: auto 0.2em;
            background-color: $yellow-color-geegr;
            color:white;
            padding: 0.2em 0.5em;
            border-radius: 0.2em;
    
        }
        div{
            span{
                color: $yellow-color-geegr;
                background-color: transparent;
            }
        }
        .country-flag-div{
            display: flex;
            flex-direction: row;
            margin: auto 1em;
            p{
                margin: 0.5em auto;
                text-align: center;
                margin: auto 2em auto 0.5em;
            }
            img{
                margin: 0.5em 0.3em;
                width:2em;
            }
        }
        .verification-btn-div{
            span{
                display: flex;
            }
            button{

            display: flex;
            flex-direction: row;

            border: none;
            background-color: $success-color-green;
            color: white;
            padding-left: 0.7em;
            padding-right: 0.7em;
            padding-top: 0.4em;
            padding-bottom: 0.4em;
            border-radius: 0.3em;
                span{
                    color:white;
                    margin: auto;

                }
                p{
                    margin:auto;
                    color:white;
                    font-weight: 400;
                    text-align: center;
                }
            }

        }
    }
}
.map-on-project-detail{
    padding: 0 2em 0 10em;
    margin: 2em auto;
    padding: 0px;
     margin-left: 88px;
     p{
        font-family: "Nunito Sans";
                        font-style:normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 23px;
                        color: #000000
     }
}
.location-title{
    font-size: 1.9em;
    font-weight: 500;
}
.project-cost-card-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.2em;
    margin: auto 1em;
    border: 1px solid white;
    background-color: white;
    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        padding: 0.2em 0.5em;
        margin: auto 1em;
        p{
            color: rgb(0, 0, 0);
            margin: 0.5em 0.2em;

        }
        p:nth-child(1){
            font-size: 0.9em;
            color: $light-gray-p;
            font-weight: 600;
            white-space: nowrap;
        }
        p:nth-child(2){
            font-size: 1.4em;
            color:black;
            font-weight: 100;
        }
    }
}
.apply-now-btn-div{
    margin: 2em auto;
    button{
        display: block;
        width:100%;
        border: none;
        background-color: $primary-color-purple;
      
        padding-left: 0.7em;
        padding-right: 0.7em;
        padding-top: 1em;
        padding-bottom: 1em;
        border-radius: 0.3em;
        text-align: center;
        min-height: 4vh;
        font-family: "Nunito Sans";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 27px;
                        color: #FFFFFF;
        
    }
}
.project-summary-div{
    border: 2px solid #F9F9F9;
    background-color: #F9F9F9;
    

    .project-summary-title{
    border: 1px solid #F0F0F0;
    background-color: #F0F0F0;
    padding: 0.5em 1.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 200;
    h3{
        font-family: "Nunito Sans";
                          font-style: normal;
                          font-weight: 400;
                          font-size: 20px;
                          line-height: 27px;
                          text-align: center;
                          color: #000000;
    }

    }
    .project-location-div, .proejct-time-div{
        display: flex;
        flex-direction: row;
        margin: 1em 3em;
        align-items: center;
        svg{
            width: 2.5vh;
            height: 2.5vh;
            color: $primary-color-purple;
        }
        p{
            margin: 0.2em 1em;
        }
        p:nth-child(2){
            color: $light-gray-p;
        }
    }

}

.custom-shadow{
    // box-shadow: 0px 0px 33px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 33px 0px rgba(0,0,0,0.1);
}
.custom-dropdown{
    max-height: 120px;
    overflow-y: scroll;
}
.align-row{
    flex-direction: row !important;
    padding: 0 !important;
}
.error{
    color:red
}
.starContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 133px;
    align-items: center;
    color: $yellow-color-geegr;
    div{
        display: flex;
        flex-direction: row;
    }

  }
  .sharIt{
    margin: 20px;
    margin-top: 30px;
    .shareiconContainer{
        color: black;
        background-color: #ffff;
        width: 30px;
height: 28px;
left: 908px;
top: 1388px;

background: #FFFFFF;
box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.23);
border-radius: 2px;
padding: 5px;
margin-right: 10px;
    }
    span{
        margin-right: 10px;
        font-family: 'Nunito Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #635757;
    }
    button{
        padding-right: 21px;
        
    }
  }

  #dropdown-basic:hover{
  color:  #707070
  }

  #dropdown-basic>span{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: #707070;
margin-left: 4px;
  }
  .dynamic-star-rating{
    display: flex;
    flex-direction: row;
  }

  .rating-div .rating-count{
    margin-top: 5px;
    width: 26px;
    height: 19px;
    padding: 0px 4px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rating-div{
    margin: 0px !important;
  }
  .rating-div > span{
    padding: 0.2em 0.15em !important;
  }
  .rating-div div{
    margin: 0px;
  }

  .rating-div div span{
    margin: 0px !important;
    padding: 0.2em 0.15em !important;
  }
  .geegr-btn{
    width: 136px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .shareBtn{
    background: none;
    color:#6A2FF9 ;
    border: none;
    outline: none;
  }
  .makeoffer{
    background-color: #fff;
  }
  .ratingTitle{
    div{
        span{
            padding: 0px !important;
        }
    }
  }
  .MuiAvatar-img{
    height: 100% !important;
  }
  // CSS OF Project Listing and Details
  .categoryContainer{
    p{
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        
        color: #3D3939;
    }
  .categorys{
    display: flex;
    align-items: center;
    input{
        width: 18px;
        height: 20px;
        border-radius: 5px;
        background: #DDDDDD;
        margin-bottom: 16px;
        color:mediumslateblue;
    }
    p{
        
            display: flex;
            align-items: center;
            margin: 0px 15px;
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 16px;
          }
    

}
  }
  .filterContainer{
    p{
        color: rgba(61, 57, 57, 0.78);
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        
    }
  }
  .css-eg0mwd-MuiSlider-thumb{
    background-color: #FFFFFF !important;
    border:1px solid !important;
  }
  
  .searchResultContainer{
    
    p{font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #3D3939;
        margin-left: 30px;
        margin-bottom: 30px;
        }
        .searchCont{
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            background: #F2F2F2;
            border-radius: 6px;
                        .serchIconTextCantainer{
                margin-left: 20px;
                display: flex;
                align-items: center;
                input{
                    outline: none;
                    padding-left: 1em !important;
    border: none;
    width: 550px;
                }
            }
        .sortContainer{
            display: flex;
            align-items: center;
            margin-right: 21px;
        }
        }
        input,button{
            background: #F2F2F2;

        }
  }


  #sortBy{
    display: flex;
    align-items: center;
    margin: 0px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #707070;

  }
  #relv{
    margin: 0px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #3D3939;
    margin-left: 10px;
}
 

.relevancedropedown{
    button{
        outline: none;
        border: none;
    }
}
.mainCardContainer{
    background: #FFFFFF !important;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07) !important;
    border-radius: 5px !important;
    margin-bottom: 30px !important;
    padding: 30px !important;
    .cardContainer{
        display: flex;
        align-items: center;
        text-decoration:none;
         color: black;
    }
    
}


.rightSideofCard{
    height: 148px;
    border-radius: 5px;
    img{
        border-radius: 5px;
        height: 148px;
    width: 155px;


    }
}


.leftSideofCard{
    margin-left: 20px;
    h1{
        
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
            color: #222222;
        
    }
    p{
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #707070;
    }
}

.iconContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 499px;
    div{
        display: flex;
        align-items: center;
        margin-right: 20px;
        span{
            margin-left: 10px;
            font-family: 'Nunito Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: center;

color: #707070;

        }
    }
}
.project-location-div{
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-left: 78px !important;
    justify-content: center;

    div{
        display: flex;
        align-items: center;
    //     svg{
    //         width: 22px !important;
    // height: 25px !important;

    //     }
    
    }
    p{
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 23px;
        color: #000000;
    }
    span{
        font-family: 'Nunito Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 22px;

color: #707070;

margin-left: 13px;

    }

}

.locationContainer{
  div{
    svg{
        width: 22px !important;
    height: 25px !important
    }
  }  
}
.postedIcon{
    margin-left: 4px;
   span{width: 20px !important;
    height: 15px !important;} 

}
.locationsContainer{
    p{
        font-family: Nunito Sans;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 27px;
                        color: #3D3939;
    }
    .search{
                        display: flex;
                        justify-content: space-between  ;
                        flex-direction: revert;
                        align-items: center;
                        padding: 0px 10px;
                        height: 48px;
                        background: "#FFFFF";
                        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
                        border-radius: 5px;
                        input{
                            text-indent: 0px;
                            outline: none;
                            border: none;
                            min-height: 100%;
                        }
                        svg{
                            color: rgba(61, 57, 57, 0.78);
                            
                        }
    }
}
.shareModelTitle{
    font-family: "Nunito Sans";
                        font-style: "normal";
                        font-weight: "700";
                        font-size: "24px";
                        line-height: "33px";
                        display: "flex";
                        align-items: "center";
                        text-align: "center";
                        color: "#000000";
                        margin-left: "198px"
}
.closeBtn{
    width: 20px !important;
    height: 30px;
    background-color: white !important;
}

.closeBtn:hover{
    width: 20px !important;
    height: 20px !important;
    background-color: white !important;
    border:none;
    transition: none !important;
    transform: none !important;
    border: none !important;
    padding: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-right: 0px !important;

}
#descript{
    font-family: 'Nunito Sans';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 23px;
margin-left: 22px;
/* or 115% */


color: #000000;
}

.breadcrum-item{
    display: flex;
    flex-direction: row;
    height: 25px;

}

.breadcrum-item:hover{
    p{
        color:rgb(25, 25, 150) !important
    }
    
}

.customer .customerContainer .breadcrumb-n-title .breadcrum-ui-kit nav div nav ol li a{
  color:#000 !important; 
}