.greetUser{
    margin: 2em auto 2em 1em ;
    width:100%;
    h1{
        font-weight: 500;

    }
   p{
    margin:0.2em 0 1em 0;
   }
}

.rating {
    span {
        color: #FDBF42;
        background-color: transparent;
    }

    .badge {
        color: #ffffff;
        background-color: #FDBF42;
    }
}

.breadcrum-item{
    display: flex;
    flex-direction: row;
    height: 25px;

}

.breadcrum-item:hover{
    p{
        color:rgb(25, 25, 150) !important
    }
    Link{
        color:rgb(25, 25, 150) !important
    }
    
}