

//gray font H1
$grey-font-h1: #9d9b9b;

//primary color Geegr
$primary-color-purple: #6A2FF9;

//success color
$success-color-green: #38b653;

//yellow color
$yellow-color-geegr: #FDBF42;

$light-gray-p: #B3B7BC;