.accordion-item {
    margin-bottom: 10px;
    border: 1px solid #e2e2e2 !important;
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important;
    border-bottom-left-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important;

    .accordion-button {
        border-top-left-radius: 0.6rem !important;
        border-top-right-radius: 0.6rem !important;
        border-bottom-left-radius: 0.6rem !important;
        border-bottom-right-radius: 0.6rem !important;

        .span-title {
            width: 660px;
        }

        .badge {
            width: 140px;
        }

    }

    .accordion-button::before {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: left;
        margin-right: 10px;
        content: "";
        background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%234f4f4f%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .2s ease-in-out;
}

    .accordion-button:not(.collapsed)::before {
        background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%233b71ca%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
        transform: rotate(-180deg);
    }

    .accordion-button::after {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: left;
        margin-right: 10px;
        content: "";
        background-image: none !important;
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .2s ease-in-out;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: none !important;
        transform: rotate(-180deg);
    }

    .accordion-button:not(.collapsed) {
        box-shadow: none !important;
    }

    .avatar {
        width: 30px;
        height: 30px;
    }
}

